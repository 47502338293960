import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import Hero from 'components/Hero';
import Footer from 'components/Footer';
import './App.scss';

const App = (): ReactElement => {
  return (
    <>
      <div className="app">
        <Header />
        <Hero />
        <div className="app__content">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;

import { ReactElement } from 'react';
import classnames from 'classnames';
import i18next from 'i18next';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import NavLink from 'components/NavLink';
import Logo from './logo.png';
import './Header.scss';

const Header = (): ReactElement => {
  const { t } = useTranslation();

  const onLanguageSwitch = (lang: string): void => {
    i18next.changeLanguage(lang);
  };

  return (
    <div className="header">
      <NavLink to="/">
        <img className="header__logo" src={Logo} alt={t('headerLogo')} />
      </NavLink>
      <nav className={classnames('header__nav')}>
        <ul className="header__menu">
          <li className="language-switcher">
            <span
              className={classnames('language-switcher__lang', {
                'language-switcher__lang--active': i18n.language === 'en',
              })}
              onClick={() => onLanguageSwitch('en')}
            >
              EN
            </span>
            {' | '}
            <span
              className={classnames('language-switcher__lang', {
                'language-switcher__lang--active': i18n.language === 'zh',
              })}
              onClick={() => onLanguageSwitch('zh')}
            >
              中文
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;

import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CranioSacral from './craniosacral.png';
import './CranioSacral.scss';

const CranioSacralTherapy = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="craniosacraltherapy">
      <p>{t('craniosacralPara1')}</p>
      <p>{t('craniosacralPara2')}</p>
      <p>
        <Trans i18nKey="craniosacralPara3">
          CST was pioneered and developed by osteopathic physician John E.
          Upledger in 1970s. For more information, visit
          <a
            href="https://www.upledger.com/index.php"
            target="_blank"
            rel="noreferrer"
          >
            upledger.com
          </a>
          .
        </Trans>
      </p>
      <img
        className="craniosacraltherapy__image"
        src={CranioSacral}
        alt={t('craniosacralImage')}
      />
    </div>
  );
};

export default CranioSacralTherapy;

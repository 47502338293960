import { ReactElement } from 'react';
import { useResolvedPath, useMatch, Link } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

const NavLink = ({ children, to, ...props }: LinkProps): ReactElement => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link className={match ? 'active' : ''} to={to} {...props}>
      {children}
    </Link>
  );
};

export default NavLink;

import { ReactElement } from 'react';
import './Footer.scss';

const Footer = (): ReactElement => {
  const today = new Date();
  const year = today.getFullYear();

  return <div className="footer">&copy; {year} Healing Touch CranioSacral</div>;
};

export default Footer;

import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';
import App from 'components/App';
import Loader from 'components/Loader';
import CranioSacralTherapy from 'routes/CranioSacralTherapy';

import './i18n';

import './styles/reset.css';
import './styles/index.scss';

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<CranioSacralTherapy />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

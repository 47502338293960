import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Hero.scss';

const Hero = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="hero">
      <h1 className="hero__title">
        <Trans t={t} i18nKey="heroTitle">
          Embark on the journey of self-healing <br /> Experience the power of
          CranioSacral Therapy
        </Trans>
      </h1>
    </div>
  );
};

export default Hero;
